import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import UtahFestivalOperaImage from '../../assets/images/publication-banners/utah-festival-opera.jpg';

export const query = graphql`
  query UfoQuery {
    allUfoCsv {
      nodes {
        SHOW
        Description
        Image
      }
    }
  }
`;

export default function UtahFestivalOpera({ data }) {
  const ufoNodes = data.allUfoCsv.nodes;

  const imageRef = '/season-images/ufo/';

  return (
    <Layout>
      <Helmet>
        <title>Utah Festival Opera - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Utah Festival Opera’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Utah Festival Opera</h1>
            </header>
            <span className="image main">
              <img src={UtahFestivalOperaImage} alt="" />
            </span>
            <p className="box">
              Performances will be held at the beautifully restored Ellen Eccles
              Theatre in Logan, Utah. Its stage accommodates all manner of
              performances, from the most intimate aria or duet, to the grandest
              spectacle. From the ornate grand foyer to the sweeping balconies,
              red plush seats, superb sight lines, and flawless acoustics, the
              Ellen Eccles Theatre is the perfect setting to be carried away to
              distant lands of passion, drama, intrigue and romance. The Opera
              will present six dramatic performances in repertory during this
              summer’s season.
            </p>
            <ul className="actions">
              <li>
                <a href="https://utahfestival.org/" className="button special">
                  <i className="icon fa-globe"> </i>Utah Festival Opera Website
                </a>
              </li>
            </ul>
            <h2>
              Utah Festival Opera <SeasonYear /> Season Information
            </h2>

            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <h4>July 3–August 5, 2024</h4>
            <h5>Advertising Information</h5>
            <section className="box">
              <table>
                <tbody>
                  <tr>
                    <td>
                      File Ready Deadline: <br />
                      June 5, 2024
                    </td>
                  </tr>
                  <tr>
                    <td>Circulation: 25,000</td>
                  </tr>
                </tbody>
              </table>
            </section>

            <div className="grid-wrapper">
              {/* Calls the season json, accesses the ecclesSeason array, and maps/creates a new array */}
              {ufoNodes.map((node) => (
                <div className="col-4 box" key={node.id}>
                  {node.Image && (
                    <img
                      style={{ width: 100 + '%' }}
                      src={imageRef + node.Image}
                    />
                  )}
                  <h3>{node.SHOW}</h3>
                  <p>{node.Description} </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
